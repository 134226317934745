import av from "./av?raw";

export enum Patform {
  h5 = "h5",
  alipay = "alipay",
  wechat = "wechat",
}

//@ts-ignore
const isdev = process.env.NODE_ENV == "development";

console.log("开发环境", isdev);
export default {
  env: Patform.h5, //alipay //wechat
  token: localStorage.getItem("token"),
  apliyPayAppid: "2021004109653575",
  isdev: isdev,
  loginAndPayHost: "gxf.uclbrt.com",
  loginAndPayPort: "5201",
  //@ts-ignore
  host: globalThis.host,
  //@ts-ignore
  port: globalThis.port || (location.protocol == "https:" ? "443" : "80"),
  devhost: "192.168.3.122", // "115.29.142.212", //"192.168.3.188",// "gxfc.uclbrt.com",//   "192.168.3.224",// "gxfc.uclbrt.com",//"192.168.3.19",//
  devport: "20001", //  9010, // 1235,// "",//  1235,//80,//
  //wx411657800f843987  //钥匙包
  //wx20dec81897ccbeec  //广州畅联

  payCheckHost:
    //@ts-ignore
    globalThis.payCheckHost ||
    (location.protocol == "https:" ? "wss" : "ws") +
      "://gxfc.uclbrt.com/_websocket/pay",
  devPayCheckHost: "ws://gxfc.uclbrt.com/_websocket/pay",

  wechatAppid: "wx20dec81897ccbeec",
  avtorImg: av,

  onPageShow: null,

  //支付查询websocket
  payRsWsdevhost: "192.168.3.19",
  payRsWsdevport: "9525",
  payRsWsHost: "gxfc.uclbrt.com",
  payRsWsPort: "9525",
  //@ts-ignore
  ws: globalThis.ws || "wss://gxfc.uclbrt.com/_websocket/noticesocket",
  devws: "ws://gxfc.uclbrt.com/_websocket/noticesocket",
};
//@ts-ignore
if (!globalThis.ws && !isdev) alert("请配置ws地址");
